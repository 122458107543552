<template>
        <div style="width: 100%;height: 100%;overflow: hidden;display: flex;flex-wrap: nowrap">
            <div style="margin-left: 250px;height: auto;overflow: hidden">
                <div class="service-card" >
                    <div v-for="(item,index) in GoodsList" :key="index" style="float: left;background-color: #FFFFFF;height: 372px;margin: 2px">
                        <div class="shop-box" @click="shopdetails(item.id)">
                            <label  v-if="item.images.length > 0">
                                <div v-for="(i,k) in item.images" :key="k" >
                                    <img :src="i.url" v-if="k === 0" style="width: 100%; height: 150px;margin-bottom: 10px;border: 1px solid #DCDCDC;object-fit: cover;">
                                    <img v-if="k < 5" :src="i.url" style="width: 44px;height: 32px;margin-right: 6px;float: left;object-fit: cover;">
                                </div>
                            </label>
                            <div style="display: flex; justify-content: space-between; margin-bottom: 10px;clear: both">
                                <div style="color: #FF0000; font-size: 20px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">￥{{item.price}}</div>
                            </div>
                            <div style="height: 24px;font-size: 18px;clear: both;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;padding-top: 5px;font-weight: bold;">{{item.title}}</div>
                            <div class="richtext" style="height: 20px;font-size: 14px;margin-top: 1px;overflow:hidden;text-overflow: ellipsis;-o-text-overflow:ellipsis;margin-bottom: 10px;" ></div>
                            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                                <div style="font-size: 14px; line-height: 28px">
                                    <span style="color: #999999">成交量：</span>
                                    <span style="color: #FF9800">3542</span>
                                </div>
                                <span class="expert-btn hand op"><i class="el-icon-chat-dot-round" style="margin-right: 2px"></i>免费咨询</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 200px;float: left"></div>
        </div>
</template>
<script>
export default {
    components: {

    },
    props: {
        id:{
            type:Number,
        },
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data () {
        return {
            GoodsList:[],
        }
    },
    mounted:function(){
        var that = this;
        that.getShopGoodsList();


    },
    methods:{
        //  获取商品列表
        getShopGoodsList(){
            var that= this;
            that.newApi.getShopGoodsList({
                type:1,
                shopId:that.id,
                page:1,
                pageSize:10,
            }).then((ret)=>{
                that.GoodsList = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        shopdetails(id){
            window.open("/shop/productDetails/" + id);
        }
    }
}
</script>
<style scoped>
.service-card .shop-box {
    width: 250px;
    padding: 16px 18px;
    border: 1px #DCDCDC solid;
    height: 339px;
}

.service-card .look-btn {
    width: 82px;
    height: 22px;
    border: 1px #FF9800 solid;
    color: #FF9800;
    padding: 6px 12px;
    border-radius: 5px;
}

.service-card .expert-btn {
    width: 82px;
    height: 22px;
    background-color: #FF9800;
    color: #FFFFFF;
    padding: 6px 12px;
    border-radius: 5px;
}

.service-card .old-ico {
    width: 46px;
    height: 10px;
    background-color: #FF9800;
    color: #FFFFFF;
    margin-right: 8px;
    padding: 0 2px;
    border-radius: 5px;
}

.service-card .up-ico {
    width: 14px;
    height: 14px;
    background-color: #0B9D35;
    color: #FFFFFF;
    margin-right: 8px;
    padding: 0 2px;
    border-radius: 5px;
}
</style>
