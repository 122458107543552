<template>
    <div style="width: 100%;height: auto;" class="overview">
        <div class="renovation-box">
            <el-carousel height="880px" arrow="never">
                <el-carousel-item v-for="(item, index) in shopData.banner" :key="index">
                    <div style="width: 100%;">
                        <img v-if="item.img_url" :src="item.img_url" width="100%" height="100%">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>

        <div class="shop-body">
            <div class="hand op" @click="toServiceInfo(item.id)" style="margin: 10px 0" v-for="(item, index) in shopData.productImages" :key="index">
                <img :src="item.img_url" style="width: 100%">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'renovationShop',
        components: {},
        data() {
            return {}
        },

        props: {
            shopData: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        mounted() {
        },

        methods: {
            // 点击跳转服务详情
            toServiceInfo(id) {
                if (id) {
                    this.utils.a('/shop/serviceVerify/' + id)
                }
            },
        }
    }

</script>
<style>
    .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 300px;
        margin: 0;
    }

    .overview .shop-body {
        min-height: 600px;
        margin: 10px auto;
        background-color: #FFFFFF;
    }

    .overview .shop-head {
        padding: 16px 20px;
        font-size: 20px;
        display: flex;
        border-bottom: 1px #E6E6E6 solid;
        justify-content: space-between;
    }

    .renovation-box .el-carousel__item:nth-child(2n) {
        min-height: 600px;
    }

    .renovation-box .el-carousel__item:nth-child(2n+1) {
        min-height: 600px;
    }
</style>
