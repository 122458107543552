<template>
    <div style="width: 100%;height: 100%;">
        <div tyle="width: 1100px;height: 470px;background: #FFFFFF;float: left" >
            <!-- 店铺logo  -->
            <div style="float: left">
                <div style="width: 250px;height: 250px;margin: 30px 40px;">
                    <img :src="myshop.logo.img_url" style="width: 250px;height: 250px;object-fit: cover;border: 1px solid #D9D9D9;">
                </div>
                <div style="display: flex">
                    <div style="margin: 22px 0 0 55px">
                        <svg t="1681700453847" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3290" width="15" height="15">
                            <path d="M511.913993 63.989249C317.882076 63.989249 159.973123 221.898203 159.973123 415.930119c0 187.323366 315.473879 519.998656 328.890979 534.103813 6.020494 6.364522 14.449185 9.976818 23.221905 9.976818 0.172014 0 0.516042 0 0.688056 0 8.944734 0 17.545439-4.128339 23.393919-11.008903l109.22896-125.054258c145.179909-177.690576 218.629934-314.957836 218.629934-407.845456C864.026877 221.898203 706.117924 63.989249 511.913993 63.989249zM511.913993 575.903242c-88.415253 0-159.973123-71.55787-159.973123-159.973123s71.55787-159.973123 159.973123-159.973123 159.973123 71.55787 159.973123 159.973123S600.329246 575.903242 511.913993 575.903242z" fill="#FF9800" p-id="3291"></path>
                        </svg>
                    </div>
<!--                    <div v-if="myshop.city.province" style="display: flex">-->
<!--                        <div class="now">{{ myshop.city.province }}</div>-->
<!--                        <div class="now">·</div>-->
<!--                        <div class="now">{{ myshop.city.market }}</div>-->
<!--                        <div class="now">·</div>-->
<!--                        <div class="now">{{ myshop.city.distinguish }}</div>-->
<!--                    </div>-->
                    <div  v-if="myshop.city.cityName" style="display: flex">
                        <div class="now" v-for="(cit, market) in myshop.city.cityName" :key="market">
                            {{ cit }}
                            <span v-if="market !== Object.keys(myshop.city.cityName).length - 1">·</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="float: left">
                <div style="height: 36px;font-size: 25px;color: #444444;margin: 30px 0 0 36px;">{{myshop.name}}</div>

                <div  style="width: 56px;height: 28px;border-radius: 2px 2px 2px 2px;margin: 16px 0 0 36px;float: left">
                    <el-tag  v-if="myshop.type === 1" style="background: #2970FF;color: #FFFFFF;font-size: 18px;">个人</el-tag>
                    <el-tag  v-else style="background: #2970FF;color: #FFFFFF;font-size: 18px;">企业</el-tag>
                </div>
                <div style="width:121px;height: 28px;float: left;margin: 16px 0 0 10px;">
                    <el-tag type="success" style="font-size: 18px;color: #FFFFFF;background: #7BD7A2;">保证金3000</el-tag>
                </div>
                <div style="font-size: 18px;color: #999999;float: left;margin: 20px 0 0 16px;">店铺成立时间：<span style="color: #2970FF;">2023</span>年</div>
                <div v-if="myshop.introduce.length != ''" style="width: 740px;font-size: 22px;margin-left: 36px;padding-top: 15px;clear: both;">店铺介绍：<span>{{myshop.introduce}}</span></div>
            </div>
            <!--   当前店铺还没有评分  -->
            <div style="width: 390px;min-height:300px;float: right">
                <div style="width: 198px;height: 26px;font-size: 20px;color: #444444;margin: 33px 0 0 40px;">当前店铺还没有评分</div>
                <div style="display: flex;margin: 25px 0 0 40px;">
                    <div style="height: 24px;font-size: 18px;color: #444444;">咨询响应：</div>
                    <div style="">
                        <el-rate v-model="valxin"></el-rate>
                    </div>
                    <div style="height: 24px;font-size: 20px;color: #E4393C;">0.0分</div>
                </div>
                <div style="display: flex;margin: 25px 0 0 40px;">
                    <div style="height: 24px;font-size: 18px;color: #444444;">交付质量：</div>
                    <div style="">
                        <el-rate v-model="valxin1"></el-rate>
                    </div>
                    <div style="height: 24px;font-size: 20px;color: #E4393C;">0.0分</div>
                </div>
                <div style="display: flex;margin: 25px 0 0 40px;">
                    <div style="height: 24px;font-size: 18px;color: #444444;">用户评价：</div>
                    <div style="">
                        <el-rate v-model="valxin2"></el-rate>
                    </div>
                    <div style="height: 24px;font-size: 20px;color: #E4393C;">0.0分</div>
                </div>
<!--                <div style="width: 148px;height: 58px;border-radius: 5px 5px 5px 5px;margin: 22px 0 0 40px;float: left">-->
<!--                    <el-button plain >在线咨询</el-button>-->
<!--                </div>-->
<!--                <div style="width: 148px;height: 58px;border-radius: 5px 5px 5px 5px;margin: 22px 0 0 14px;float: left">-->
<!--                    <el-button plain >电话联系</el-button>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['myshop'],
    components: {

    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data () {
        return {
            valxin:null,
            valxin1:null,
            valxin2:null,
            show:false,
        }
    },
    created() {

    },
    mounted:function(){
        console.log(this.myshop)

    },
    methods:{

    }
}
</script>
<style scoped>
.now {
    height: 21px;
    font-size: 18px;
    color: #666666;
    margin: 20px 0 0 5px;
}
</style>