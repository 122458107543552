<template>
    <div style="width: 100%;height: 100%">
       <div v-if="ShopCase.length" style="width: 1500px;height: 80px;border: 1px solid #DCDCDC;background: #FFFFFF;margin-left: 200px;">
           <div style="width: 1500px;height: 80px;background: #FFFFFF;border: 1px solid #DCDCDC;">
               <div style="font-size: 20px;margin: 28px 44px;float: left;cursor: pointer">服务案例</div>
               <div style="height: 80px;border-left: 2px solid #DCDCDC;float: left"></div>
<!--               <div v-for="(name,index) in services" :key="index">-->
<!--                     <div style="font-size: 20px;margin: 28px 20px;float: left;cursor: pointer;">{{name.title}}</div>-->
<!--               </div>-->
               <div style="width: 280px;height: 56px;float: right;margin-top: 22px;margin-right: 22px;">
                   <el-input
                       placeholder="请输入关键词搜索..."
                       suffix-icon="el-icon-search"
                       v-model="search">
                   </el-input>
               </div>
           </div>

       </div>
        <div style="width: 1500px;background: #FFFFFF;margin-left: 200px;clear: both">
            <div v-for="(label,index) in ShopCase" :key="index" style="width: 373px;height: 360px;border: 1px solid #DCDCDC;float: left;background: #FFFFFF;display: flex;flex-wrap: wrap;margin-top: 5px;">
                <div style="margin: 16px 0 0 20px">
                    <img width="320px;" height="237px" :src="label.img" style="object-fit: cover;">
                </div>
                <div style="height: 24px;font-size: 20px;color: #444444;margin-left: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{label.title}}</div>
                <div style="display: flex">
                    <div @click="caseDetailsPage(label.id)" style="width: 125px;height: 38px;margin: 13px 0 0 22px;">
                        <el-button size="medium" type="primary">查看详情</el-button>
                    </div>
                    <div style="height: 38px;width: 160px;margin: 13px 0 0 22px;">
                        <el-button size="medium" type="success">免费咨询服务商</el-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default{
    components: {

    },
    computed: {

    },
    props: {
        id:{
            type:Number,
        },
    },
    data () {
        return {
            search:"",
            data_content:[{name:'全部',type:1},{name:'景观园林设计',type:2},{name:'建筑设计',type:3},{name:'市政工程',type:4}],
            ShopCase:[],
            // services:[],
        }
    },
    mounted:function(){
        this.getShopCase();
        // this.getAllShopServices();
    },
    methods:{
        getShopCase(){
            var that= this;
            that.newApi.getShopCase({
                shopId:that.id,
                page:1,
                pageSize:10,
            }).then((ret)=>{
                that.ShopCase = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 获取所有的服务
        // getAllShopServices(){
        //     var that= this;
        //     that.newApi.getAllShopServices({}).then((ret)=>{
        //         that.services = ret.data.slice(0,4)
        //     }).catch((err)=>{
        //         console.log(err)
        //     })
        // },
        caseDetailsPage(id){
            window.open("/shop/caseDetailsPage/" + id);
        }
    }
}

</script>

<style scoped>

</style>