<template>
    <div>
        <div style="width: 1500px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;height: 1050px;">
            <div style="width: auto;height: 64px;background: #FFFFFF;border: 1px solid #E6E6E6;">
                <div style="width: auto;height: 24px;color: #444444;margin: 20px 0 0 40px;font-size: 20px;font-weight: 400;">店铺热销服务</div>
            </div>
            <div v-for="(label,index) in services" :key="index" style="height: auto;background: #FFFFFF;float: left;margin-left: 70px;">
                <div style="float: left;margin-top: 25px">
                    <div v-for="(itar,label) in label.images" :key="label" >
                        <img v-if="label === 0" :src="itar.url" style="width: 224px;height: 150px;border-radius: 6px 6px 0 0;margin:5px 18px;border: 1px solid rgb(220, 220, 220);object-fit: cover;">
                        <div style="margin-left: 18px;">
                            <img v-if="label < 5" :src="itar.url"  style="width: 40px;height: 36px;margin-right: 6px;float: left;object-fit: cover;">
                        </div>
                    </div>
                </div>
                <div style="float: left;margin-top: 30px">
                    <div style="width: 400px;height: auto;">
                        <div style="font-size: 24px;color: #444444;height: 60px;overflow: hidden;text-overflow: ellipsis;-webkit-ine-clamp:2;display: -webkit-box;-webkit-box-orient: vertical">{{label.title}}</div>
                    </div>
                    <div style="width: 349px;height: 33px;margin-top: 16px;">
                        <div style="color: red;font-size: 24px;height: 33px;">￥{{label.minPrice}}</div>
                    </div>
                    <div style="font-size: 18px;color: #999999;width: auto;height: 21px;margin-top: 16px;float: left">成交量：<span style="color: #FF9800">3542</span>次</div>
                    <div style="font-size: 18px;color: #999999;width: auto;height: 21px;margin-top: 16px;float: left;margin-left: 15px;">好评率：<span style="color: #FF9800">98%</span>次</div>
                    <div @click="toMessage(myshop.uuid, myshop.name)" style="clear:both;margin-top: 16px;float: left">
                        <el-button size="small">在线咨询</el-button>
                    </div>
                    <div @click="toBuyService(label.id)" style="margin-top: 16px;float: left;margin-left: 20px;">
                        <el-button type="primary"  size="small">立即购买</el-button>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :close-on-click-modal="false" class="show-message" :visible.sync="showMessage" width="940px">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>
        <div v-if="ShopCase.length > 0" style="width: 1500px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;height: 900px;">
            <div style="width: auto;height: 64px;background: #FFFFFF;border: 1px solid #E6E6E6;">
                <div style="width: auto;height: 24px;color: #444444;margin: 20px 0 0 40px;font-size: 20px;font-weight: 400;">店铺优选案例</div>
            </div>
            <div style="margin: 20px 0 0 30px;">
                <div v-for="(type,name) in ShopCase" :key="name" style="width: 360px;height: 360px;border: 1px solid #DCDCDC;float: left">
                    <div style="margin: 16px 0 0 20px">
                        <img width="320px;" height="237px"  :src="type.img">
                    </div>
                    <div style="height: 24px;font-size: 20px;color: #444444;margin-left: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{type.title}}</div>
                    <div @click="caseDetailsPage(type.id)" style="width: 125px;height: 38px;margin: 13px 0 0 22px;float: left">
                        <el-button size="medium" type="primary">查看详情</el-button>
                    </div>
                    <div style="height: 38px;width: 160px;float: left;margin: 13px 0 0 10px;">
                        <el-button size="medium" type="success">免费咨询服务商</el-button>
                    </div>
                </div>
            </div>
        </div>
<!--        <div style="width: 1500px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;height: 1200px">-->
<!--            <div style="width: auto;height: 64px;background: #FFFFFF;border: 1px solid #E6E6E6;">-->
<!--                <div style="width: auto;height: 24px;color: #444444;margin: 20px 0 0 40px;font-size: 20px;font-weight: 400;">客户评价</div>-->
<!--            </div>-->
<!--            <div style="width: 60px;height: 24px;font-size: 20px;color: #2970FF;margin: 30px 0 0 56px;">好评度</div>-->
<!--            <div style="width: 93px;height: 59px;font-size: 26px;color: #F12939;margin: 12px 0 0 56px;float: left">98%</div>-->
<!--            <div v-for="(olw,dec) in 10" :key="dec" >-->
<!--                <div style="width: 148px;height: 40px;border: 2px solid #E0E0E0;float: left;margin: 5px;">-->
<!--                    <div style="font-size: 20px;width: 119px;height: 24px;color: #999999;margin: 8px 0 0 16px;">服务到位(10)</div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div style="width: 1440px;height: 71px;background: #F8F8F8;border: 1px solid rgb(220, 220, 220);float: left;margin: 30px;">-->
<!--                <div style="margin: 23px 0 0 30px;float: left">-->
<!--                    <el-radio-group v-model="radio">-->
<!--                        <el-radio :label="1">全部</el-radio>-->
<!--                        <el-radio :label="2">有图</el-radio>-->
<!--                        <el-radio :label="3">有追评</el-radio>-->
<!--                    </el-radio-group>-->
<!--                </div>-->
<!--                <div style="width: 72px;height: 21px;font-size: 18px;color: #555555;float: right;margin-right: 42px;margin-top: 25px;">默认排序</div>-->
<!--            </div>-->
<!--            <div style="width: 1211px;height: 974px;">-->
<!--                <div style="width: 50px;height: 50px;margin: 30px 0 0 40px;float: left">-->
<!--                    <img width="100%;" height="100%" style="border-radius: 50%" src="https://oss.baigongbao.com/2021/12/03/20211203_104657_185181.png">-->
<!--                </div>-->
<!--                <div style="float: left;">-->
<!--                    <div style="width: 80px;height: 24px;font-size: 20px;color: #666666;margin: 31px 0 0 14px;">快乐星球</div>-->
<!--                    <div style="width: 93px;height: 19px;font-size: 16px;color: #999999;margin: 4px 0 0 14px;">2022.03.12</div>-->
<!--                </div>-->
<!--                <div style="margin-top: 32px;float: left">-->
<!--                    <div style="margin-left: 53px;"><svg v-for="(i_list,inde) in 5" :key="inde" t="1673345003014" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8122" width="16" height="16"><path d="M781.186088 616.031873q17.338645 80.573705 30.59761 145.848606 6.119522 27.537849 11.219124 55.075697t9.689243 49.976096 7.649402 38.247012 4.079681 19.888446q3.059761 20.398406-9.179283 27.027888t-27.537849 6.629482q-5.099602 0-14.788845-3.569721t-14.788845-5.609562l-266.199203-155.027888q-72.414343 42.836653-131.569721 76.494024-25.498008 14.278884-50.486056 28.557769t-45.386454 26.517928-35.187251 20.398406-19.888446 10.199203q-10.199203 5.099602-20.908367 3.569721t-19.378486-7.649402-12.749004-14.788845-2.039841-17.848606q1.01992-4.079681 5.099602-19.888446t9.179283-37.737052 11.729084-48.446215 13.768924-54.055777q15.298805-63.23506 34.677291-142.788845-60.175299-52.015936-108.111554-92.812749-20.398406-17.338645-40.286853-34.167331t-35.697211-30.59761-26.007968-22.438247-11.219124-9.689243q-12.239044-11.219124-20.908367-24.988048t-6.629482-28.047809 11.219124-22.438247 20.398406-10.199203l315.155378-28.557769 117.290837-273.338645q6.119522-16.318725 17.338645-28.047809t30.59761-11.729084q10.199203 0 17.848606 4.589641t12.749004 10.709163 8.669323 12.239044 5.609562 10.199203l114.231076 273.338645 315.155378 29.577689q20.398406 5.099602 28.557769 12.239044t8.159363 22.438247q0 14.278884-8.669323 24.988048t-21.928287 26.007968z" p-id="8123" fill="#E4393C"></path></svg></div>-->
<!--                    <div style="width: 88px;height: 19px;font-size: 16px;color: #999999;margin: 5px 0 0 40px;float: left">规格：XL</div>-->
<!--                    <div style="width: 88px;height: 19px;font-size: 16px;color: #999999;margin: 5px 0 0 20px;float: left">颜色：黑色</div>-->
<!--                    <div style="margin-left: 920px;">-->
<!--                        <div style="float: left;margin-top: 5px"><svg t="1663148294845" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2432" width="20" height="20"><path d="M857.28 344.992h-264.832c12.576-44.256 18.944-83.584 18.944-118.208 0-78.56-71.808-153.792-140.544-143.808-60.608 8.8-89.536 59.904-89.536 125.536v59.296c0 76.064-58.208 140.928-132.224 148.064l-117.728-0.192A67.36 67.36 0 0 0 64 483.04V872c0 37.216 30.144 67.36 67.36 67.36h652.192a102.72 102.72 0 0 0 100.928-83.584l73.728-388.96a102.72 102.72 0 0 0-100.928-121.824zM128 872V483.04c0-1.856 1.504-3.36 3.36-3.36H208v395.68H131.36A3.36 3.36 0 0 1 128 872z m767.328-417.088l-73.728 388.96a38.72 38.72 0 0 1-38.048 31.488H272V476.864a213.312 213.312 0 0 0 173.312-209.088V208.512c0-37.568 12.064-58.912 34.72-62.176 27.04-3.936 67.36 38.336 67.36 80.48 0 37.312-9.504 84-28.864 139.712a32 32 0 0 0 30.24 42.496h308.512a38.72 38.72 0 0 1 38.048 45.888z" p-id="2433"></path></svg></div>-->
<!--                        <div style="width: 36px;height: 21px;font-size: 18px;color: #999999;float: right;margin: 5px 0 0 5px;">赞同</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div style="font-size: 18px;color: #666666;float: left;margin-left: 104px;">-->
<!--                    <div style="margin-top: 30px;">材质工艺：材质工艺：材质不错，磨砂质感，磁性也不错，吸螺丝很稳。</div>-->
<!--                    <div style="margin-top: 12px;">功能设计：很人性化的设计，特别是那个自动扣，往上拨，一排的螺丝刀成队的排列着。</div>-->
<!--                    <div style="margin-top: 12px;">操作控制：螺丝刀取出来很方便，规格齐全。放回去也合理。合起来就自动吸住。</div>-->
<!--                    <div style="margin-top: 12px;">外形外观：简单的黑色设计，磨砂的外壳，跟上档次，满意。</div>-->
<!--                </div>-->
<!--                <div style="width: 590px;height: 590px;margin-top: 20px;margin-left:104px">-->
<!--                    <img style="width: 100%;height: 100%;border-radius: 6px;" src="https://oss.baigongbao.com/img/v2_psaxco.png">-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>
<script>
import ToMessage from "../order/ToMessage";
export default{
    components: {
        ToMessage
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    props: {
        id:{
            type:Number,
            default() {
                return {}
            }
        },
        myshop: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data () {
        return {
            radio: 1,
            services:[],
            ShopCase:[],
            serviceInfo:{},

            showMessage: false,
            uuid: '',
            nickName: '',
        }
    },
    mounted:function(){
        this.getAllShopServices();
        this.getShopCase();

    },
    methods:{
        // 获取所有的服务
        getAllShopServices(){
            var that= this;
            that.newApi.getAllShopServices({
                pageSize:8,
            }).then((ret)=>{
                that.services = ret.data.slice(0,8)
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 获取店铺案例
        getShopCase(){
            var that= this;
            that.newApi.getShopCase({
                shopId:that.id,
                page:1,
                pageSize:10,
            }).then((ret)=>{
                that.ShopCase = ret.data.slice(0,8);
            }).catch((err)=>{
                console.log(err)
            })
        },
        caseDetailsPage(id){
            window.open("/shop/caseDetailsPage/" + id);
        },
        getShopServiceInfo:function(id){
            var that = this;
            that.newApi.getShopServiceInfo({
                serviceId:id,
            }).then((res)=>{
                that.serviceInfo = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 购买服务
        toBuyService(id) {
            var that = this;
            that.newApi.getShopServiceInfo({
                serviceId:id,
            }).then((res)=>{
                if (res.isSuccess === 1){
                    that.serviceInfo = res.data;
                    if (!this.user.corpMemberId && !this.user.individMemberId) {
                        this.utils.confirm('此账号未绑定银行卡，不能购买服务，是否前往绑定？', function () {
                            that.$router.push({path: '/user/money'})
                        })
                    } else {
                        that.$router.push({path: '/shop/serviceVerify/' + that.serviceInfo.id, query: {roomId: that.$route.query.roomId, sequenceId: that.$route.query.taskId}})
                    }
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 私信
        toMessage(uuid, name) {
            this.uuid = uuid;
            this.nickName = name;
            this.showMessage = true
        },

    }
}
</script>
<style scoped>
/deep/ .el-radio__inner {
    width: 20px;
    height: 20px;
}
</style>
