<template>
	<div style="width: 100%;height: auto;" class="renovation-shop">
		<div>
			<div class="renovation-box">
				<div class="title-form"><span style="color:#FF0000">*</span>店铺名称</div>
				<div>
					<el-input style="width: 800px" v-model="shopData.name" :show-word-limit="true" maxlength="30" minlength="4"
							  placeholder="店铺名称"></el-input>
				</div>
			</div>

			<div class="renovation-box" style="display: flex">
                <div style="margin-right: 200px">
                    <div class="title-form"><span style="color:#FF0000">*</span>店铺封面</div>
                    <div>
                        <el-upload class="upload-demo4" drag multiple
                                   action="https://jsonplaceholder.typicode.com/posts/"
                                   :show-file-list="false" :http-request="(val) =>{ uploadBackImg(val, 'cover') }">
                            <img v-if="shopData.cover.img_url != ''" :src="shopData.cover.img_url" style="width: 100%;">
                            <div v-else style="margin-top: 70px">
                                <i class="el-icon-plus icon-plus"></i>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div>
                    <div class="title-form"><span style="color:#FF0000">*</span>店铺Logo<span style="font-size: 14px; color: #a6a6a6; font-weight: 400">（120×120像素）</span></div>
                    <div>
                        <el-upload class="upload-demo3" drag multiple
                                   action="https://jsonplaceholder.typicode.com/posts/"
                                   :show-file-list="false" :http-request="(val) =>{ uploadBackImg(val, 'logo') }">
                            <img v-if="shopData.logo.img_url != ''" :src="shopData.logo.img_url" style="width:120px; height: 120px">
                            <div v-else style="margin-top: 40px">
                                <i class="el-icon-plus icon-plus"></i>
                            </div>
                        </el-upload>
                    </div>
                </div>
			</div>

            <div class="renovation-box" style="margin-bottom: 40px">
                <div class="title-form">店铺经营范围</div>
                <div style="display: flex">
                    <div>
                        <div v-for="(item,index) in type_list" :key="index">
                            <div @click="changeColor(item)" :style=" currentColor === item ? 'background: #CED0D4' : ''" style="width: 203px;height: 69px;background: #FFFFFF;border: 1px solid #CED0D4;">
                                <div style="width: 72px;height: 21px;font-size: 18px;color: #707070;margin: 24px 0 0 70px;cursor: pointer" >{{item.label}}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="currentCategory">
                            <div style="width: 1169px;min-height: 424px;background: #FFFFFF;border: 1px solid #CED0D4;">
                                <div style="width: 100%;height: 21px;font-size: 18px;color: #707070;margin: 24px 0 0 61px;">二级分类（多选，至少选择一项，最多选择20项）</div>
                                <div style="display: flex; flex-wrap: wrap">
                                    <div v-for="(item2,index2) in currentCategory.children" :key="index2" style="height: auto;background: #FFFFFF;border: 1px solid #CED0D4;margin: 20px 0 0 61px;">
                                        <div style="height: auto;font-size: 16px;color: #666666;cursor: pointer" :class="{ selected: isSelected(item2) }" @click="add_type_list(item2)"><span style="margin: 10px;">{{item2.label}}</span></div>
                                    </div>
                                    <div style="width: 1168px;border-top: 1px solid #CED0D4;margin-top: 10px"></div>
                                    <div style="width: auto;color: #444444;height: 21px;font-size: 18px;margin: 24px 0 0 60px;">当前已选择：<span style="color: #2970FF">{{selectedCount}}</span>项</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="renovation-box" style="margin-bottom: 2px">
                <div class="title-form"><span style="color:#FF0000">*</span>店铺经营地址</div>
                <div style="display: flex">
		            <div style="margin: 16px 0 0 0;">
			            <address-selector @change="selectCity" :address ="city"  :lv="3" :info="true" :multiple="false"></address-selector>
		            </div>
                </div>
            </div>

            <div class="renovation-box" style="margin-bottom: 40px">
                <div class="title-form"><span style="color:#FF0000">*</span>店铺介绍</div>
                <div>
                    <el-input style="width: 766px;" type="textarea" resize="none" :rows="8" placeholder="店铺介绍" v-model="sendContent" :autosize="{ minRows: 8, maxRows: 16 }" :show-word-limit="true" maxlength="500" minlength="10"  />
                </div>
            </div>

			<div class="renovation-box" style="margin-bottom: 40px">
				<div class="title-form"><span style="color:#FF0000">*</span>店铺导图</div>
				<div>
                    <div style="display: flex; margin: 10px 0 30px 10px">
                        <div style="font-weight: 600; color: #999999">可选择默认导图：</div>
                        <el-radio style="margin-top: 5px" v-for="(item, index) in imgList" :key="index" @input="checkoutImage" v-model="shopData.backgroundImg.img_url" :label="item.img_url">{{item.name}}</el-radio>
                    </div>
					<el-upload class="upload-demo1" drag multiple
							   action="https://jsonplaceholder.typicode.com/posts/"
							   :show-file-list="false" :http-request="(val) =>{ uploadBackImg(val, 'back') }">
						<img v-if="shopData.backgroundImg.img_url != ''" :src="shopData.backgroundImg.img_url" width="100%">
						<div>
							<i class="el-icon-plus icon-plus"></i>
							<div style="font-size: 20px">将图片拖到此处，或<em>点击上传</em></div>
						</div>
					</el-upload>
				</div>
			</div>

			<div class="renovation-box">
				<div class="title-form"><span style="color:#FF0000">*</span>店铺轮播图</div>
				<el-carousel indicator-position="outside" height="600px">
					<el-carousel-item v-for="(item, index) in backImgList" :key="index">
						<div style="width: 100%;">
							<div class="hand op" @click="deleteImg(index)" v-if="item.img_url" style="color: #6d737b; font-size: 30px; font-weight: 600; display: flex; justify-content: end; margin: 4px 10px"><i class="el-icon-circle-close"></i></div>
							<el-upload class="upload-demo" drag multiple
									   action="https://jsonplaceholder.typicode.com/posts/"
									   :show-file-list="false" :http-request="(val) =>{ uploadFrontImg(val, index)}">
								<img v-if="item.img_url" :src="item.img_url" width="100%" height="100%">
								<div style="margin-top: 260px">
									<i class="el-icon-plus icon-plus"></i>
									<div style="font-size: 20px">将图片拖到此处，或<em>点击上传</em></div>
								</div>
							</el-upload>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>

            <div class="renovation-box">
                <div class="shop-head">
                    <div style="font-weight: 600">店铺推荐图片</div>
                </div>
                <div style="padding: 20px 30px; overflow: hidden; background-color: #FFFFFF; display: flex; flex-wrap: wrap">
                    <div class="renovation-card" style="margin: 10px" v-for="(item, index) in shopData.productImages" :key="index">
                        <img :src="item.img_url" style="height: 180px; width: 100%">

                        <div style="margin-top: 10px; display: flex; justify-content: center">
                            <div class="renovation-btn hand op" style="background-color: #2970FF" @click="openShopContent(index)">关联</div>
                            <div class="renovation-btn hand op" style="background-color: #A8A8A8" @click="deleteRecommendImg(index)">删除</div>
                        </div>
                    </div>

                    <div style="margin: 10px">
                        <el-upload class="upload-demo2" drag multiple
                                   action="https://jsonplaceholder.typicode.com/posts/"
                                   :show-file-list="false" :http-request="(val) =>{ uploadBackImg(val, 'recommend')}">
                            <div style="margin-top: 90px; color: #2970FF">
                                <i class="el-icon-plus icon-plus" style="margin-bottom: 10px; font-size: 30px; font-weight: 600"></i>
                                <div style="font-size: 18px">点击上传图片</div>
                            </div>
                        </el-upload>
                    </div>
                </div>
            </div>

			<div class="renovation-box" style="display: flex; justify-content: center; margin-bottom: 80px;">
				<div class="foot-btn hand op" style="margin-right: 60px; color: #FFFFFF; background-color: #A8A8A8" @click="closeEdit">取消</div>
				<div class="foot-btn hand op" style="color: #FFFFFF; background-color: #2970FF" @click="editShops">装修店铺</div>
			</div>
		</div>

        <!--店铺商品弹窗-->
        <el-dialog :close-on-click-modal="false" :visible.sync="showShop" width="44%" :show-close="false" class="event-form">
            <div class="top">
                关联店铺服务
                <img src="../../assets/imgs/index/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showShop = false">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <div style="display: flex; flex-wrap: wrap;">
                    <div v-for="(item, index) in serviceList" :key="index">
                        <div @click="selectService(item)" class="hand op" style="width: 210px; height: 80px; border: 1px solid #DFDFDF; padding: 10px; margin: 0 10px 10px 0" :style="selectOne.id === item.id ? 'border: 2px solid #2970FF': ''">
                            <div style="font-weight: 600; margin-bottom: 10px; font-size: 16px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.title}}</div>
                            <div style="font-size: 14px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;"><span v-for="(label, key) in item.label" :key="key">{{label}}/</span></div>
                        </div>
                    </div>
                </div>

                <div style="padding-bottom: 20px;text-align: center;">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="100"
                        :page-size="pageSize">
                    </el-pagination>
                </div>

                <div style="display: flex; justify-content: end">
                    <div class="hand op" @click="deleteSelect" style="border: 1px solid #DDDDDD;font-size: 16px; padding: 4px 16px; border-radius: 5px; margin: 10px">取消关联</div>
                    <div class="hand op" @click="sureSelect" style="background-color: #2970FF; color: #FFFFFF; font-size: 16px; padding: 4px 16px; border-radius: 5px; margin: 10px">确认</div>
                </div>

            </div>
        </el-dialog>
	</div>
</template>

<script>
	import AddressSelector from "@/components/common/city/AddressSelect.vue";
	
	export default {
		name: 'renovationShop',
		components: {AddressSelector},
		data() {
			return {
				backImgList: [{}],
				showImg: false,

                showShop: false,
                page: 1,
                pageSize: 9,
                serviceList: [],
                selectOne: {},

                shopData: {
					name: '',
                    cover: {
                        img_url: '',
                        name: '',
                        size: '',
                        type: '',
                        url: ''
                    },
					logo: {
						img_url: '',
						name: '',
						size: '',
						type: '',
						url: ''
					},
					banner: [],
					backgroundImg: {
						img_url: '',
						name: '',
						size: '',
						type: '',
						url: ''
					},
                    productImages: [],
				},
                type_list:[],
                currentColor: -1,
                currentCategory: null,
                selectedItems: [],
                selectedCount: 0,
                selectedProvince: null,

                sendContent: '',
				city: {
					cityId : [],
					cityName : [],
					address:""
				},
                now_id:"",
                // 查询城市接口

                imgList: [
                    {name: 1, img_url: 'https://oss.baigongbao.com/img/banner1.jpeg'},
                    {name: 2, img_url: 'https://oss.baigongbao.com/img/banner2.jpeg'},
                    {name: 3, img_url: 'https://oss.baigongbao.com/img/banner3.jpeg'},
                    {name: 4, img_url: 'https://oss.baigongbao.com/img/banner4.jpeg'},
                    {name: 5, img_url: 'https://oss.baigongbao.com/img/banner5.jpeg'},
                    {name: 6, img_url: 'https://oss.baigongbao.com/img/banner6.jpeg'},
                    {name: 7, img_url: 'https://oss.baigongbao.com/img/banner7.jpeg'},
                    {name: 8, img_url: 'https://oss.baigongbao.com/img/banner8.jpeg'},
                    {name: 9, img_url: 'https://oss.baigongbao.com/img/banner9.jpeg'},
                ]
			}
		},

        props: {
            shopId: {
                type: Number
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            }
        },

		mounted() {
			this.getMyShop();
            this.getTypeLists()
		},

		methods: {
			selectCity(value) {
				this.address = value;
			},

            add_type_list(value){
                if (this.isSelected(value)) {
                    this.selectedItems.splice(this.selectedItems.indexOf(value), 1);
                    this.selectedCount--;
                } else {
                    if (this.selectedItems.length < 20) {
                        delete value.children
                        this.selectedItems.push(value);
                        this.selectedCount++;
                    } else {
                        this.utils.err("最多只能添加20个")
                    }
                }
            },
            isSelected(index) {
	            return this.selectedItems.findIndex(item => item.value === index.value) !== -1
            },
            changeColor(item) {
                this.currentCategory = item;
            },
            getTypeLists(){
                var that = this;
                that.newApi.getTypeList({
                    lv: 3,
                }).then((res)=>{
                    that.type_list = res.data;
					that.currentCategory = that.type_list[0];
                }).catch((err)=>{
                    console.log(err)
                })
            },

            // 选择默认导图
            checkoutImage(item) {
                this.shopData.backgroundImg = {
                    img_url: item,
                    name: '默认导图',
                    size: '',
                    type: '',
                    url: ''
                }
            },

            // 删除推荐图片
            deleteRecommendImg(index) {
                this.shopData.productImages.splice(index, 1)
            },

            // 取消关联
            deleteSelect() {
                this.selectOne = {};
                this.shopData.productImages[this.serviceId].id = '';
            },

            // 确定关联
            sureSelect() {
                this.shopData.productImages[this.serviceId].id = this.selectOne.id;
                this.selectOne = {};
                this.showShop = false;
            },

            // 选择服务
            selectService(item) {
                console.log(item)
                this.selectOne = item
                console.log(this.selectOne)
            },


            // 打开店铺商品
            openShopContent(index) {
                this.showShop = true;
                this.serviceId = index;
                this.selectOne.id = this.shopData.productImages[index].id
                this.newApi.getShopServiceList({shopId: this.shopId, page: this.page, pageSize: this.pageSize}).then(res => {
                    this.serviceList = res.data
                })
            },


			// 查询我的店铺
			getMyShop(){
				var that= this;
				that.newApi.getMyShop({}).then((res)=>{
                    for (const item of res.data.business) {
                        that.add_type_list(item)
                    }
					that.shopData.id = res.data.id;
					that.shopData.name = res.data.name;
                    that.sendContent = res.data.introduce;
                    that.city = res.data.city
                    if (res.data.banner.length !== 0) {
						that.backImgList = res.data.banner;
						that.backImgList.push({});
					}
					that.shopData.logo = res.data.logo;
                    that.shopData.cover = res.data.cover;
					that.shopData.backgroundImg = res.data.backgroundImg;
                    that.shopData.productImages = res.data.productImages
				}).catch((err)=>{
					console.log(err)
				})
			},

			// 确认装修店铺
			editShops() {
				if (!this.shopData.name) {
					this.utils.err('店铺名称不能为空！');
					return false
				}
				if (!this.shopData.logo) {
					this.utils.err('店铺Logo不能为空！');
					return false
				}
                if (!this.shopData.cover) {
                    this.utils.err('店铺封面不能为空！');
                    return false
                }
				if (!this.shopData.backgroundImg) {
					this.utils.err('店铺背景图片不能为空！');
					return false
				}
				if (!this.backImgList[0]) {
					this.utils.err('请上传店铺轮播图！');
					return false
				}
                if (this.address == ""){
                    this.utils.err('请填写详细地址');
                    return false
                }
                if(this.sendContent == ""){
                    this.utils.err('请填写店铺介绍'); return false
                }

				var params = {};
                params.business = JSON.stringify(this.selectedItems);
                params.city = JSON.stringify(this.address);
                params.introduce = this.sendContent;
				params.shopId =  this.shopData.id;
				params.name = this.shopData.name;
				params.logo = JSON.stringify(this.shopData.logo);
                params.cover = JSON.stringify(this.shopData.cover);
				params.backgroundImg = JSON.stringify(this.shopData.backgroundImg);
				params.banner = this.backImgList;
                params.productImages = JSON.stringify(this.shopData.productImages);
				if (JSON.stringify(params.banner[params.banner.length -1] !== '{}')) {
					params.banner.pop();
				}
				params.banner = JSON.stringify(params.banner);
				this.newApi.editShop(params).then(res => {
					if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.$parent.toOverview()
					}
				})
			},

			// 取消装修
			closeEdit() {
				this.$parent.toOverview()
			},

			// 删除图片
			deleteImg(index) {
				this.backImgList.splice(index, 1)
			},

			// 上传图片
			uploadFrontImg(params, index) {
				var that = this;
				var file = params.file;
				const isLt5M = file.size / 1024 / 1024 < 20;
				if (isLt5M) {
					if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {

						const blob = URL.createObjectURL(file);
						this.crop(1920,800,blob,function (url) {
							that.backImgList[index].img_url = url;
							that.backImgList[index].name = file.name;
							that.backImgList[index].size =  Math.floor(file.size);
							that.backImgList[index].type = file.type;
							that.backImgList[index].url = "";
							if (JSON.stringify(that.backImgList[that.backImgList.length - 1]) !== '{}') {
								that.backImgList.push({})
							}
						})
					} else {
						this.$message.error('只能上传图片类型!')
					}

				} else {
					this.$message.error('上传文件大小不能超过 20MB!')
				}
			},

			uploadBackImg(params, type) {
				const that = this;
				const file = params.file;
				console.log(params)
				const isLt5M = file.size / 1024 / 1024 < 20;
				if (isLt5M) {
					if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {

						let cropWidth = 0;
						let cropHeight = 0;
						if (type === 'logo') {
							cropWidth = cropHeight = 120;
						}
						if (type === 'cover') {
							cropWidth = 330;
							cropHeight = 166;
						}
						//轮播 1920*880
						if (type === 'back') {
							cropWidth = 1920;
							cropHeight = 120;
						}
						if (type === 'recommend') {
							cropWidth = 1920;
							cropHeight = 1080;
						}


						const blob = URL.createObjectURL(file);
						this.crop(cropWidth,cropHeight,blob,function (url) {
							if (type === 'logo') {
								that.shopData.logo.img_url = url;
								that.shopData.logo.name = file.name;
								that.shopData.logo.size =  Math.floor(file.size);
								that.shopData.logo.type = file.type;
								that.shopData.logo.url = "";
							}

                            if (type === 'cover') {
                                that.shopData.cover.img_url = url;
                                that.shopData.cover.name = file.name;
                                that.shopData.cover.size =  Math.floor(file.size);
                                that.shopData.cover.type = file.type;
                                that.shopData.cover.url = "";
                            }
							if (type === 'back') {
								that.shopData.backgroundImg.img_url = url;
								that.shopData.backgroundImg.name = file.name;
								that.shopData.backgroundImg.size =  Math.floor(file.size);
								that.shopData.backgroundImg.type = file.type;
								that.shopData.backgroundImg.url = "";
							}
                            if (type === 'recommend') {
                                that.shopData.productImages.push(
                                    {
                                        img_url: url,
                                        name:  file.name,
                                        size: Math.floor(file.size),
                                        type: file.type,
                                        url: "",
                                        id: ''
                                    }
                                )
                            }

						})
					} else {
						this.$message.error('只能上传图片类型!')
					}

				} else {
					this.$message.error('上传文件大小不能超过 20MB!')
				}
			},
		}
	}

</script>
<style>
	.renovation-shop .shop-body {
		width: 1500px;
		min-height: 600px;
		margin: 10px auto;
		background-color: #FFFFFF;
	}

	.renovation-shop .shop-head {
		padding: 16px 20px;
		font-size: 20px;
		display: flex;
		border-bottom: 1px #E6E6E6 solid;
		justify-content: space-between;
	}

	.renovation-box .el-carousel__item:nth-child(2n) {
		min-height: 600px;
	}

	.renovation-box .el-carousel__item:nth-child(2n+1) {
		min-height: 600px;
	}


	.renovation-shop .upload-demo .el-upload-dragger {
		width: 1783px;
		height: 600px;
	}

    .renovation-shop .upload-demo1 .el-upload-dragger {
        width: 1783px;
        height: 120px;
    }

    .renovation-shop .upload-demo2 .el-upload-dragger {
        width: 300px;
        height: 260px;
    }

    .renovation-shop .upload-demo3 .el-upload-dragger {
        width: 120px;
        height: 120px;
    }
	.renovation-shop .renovation-box {
		padding: 30px 60px;
	}

	.renovation-shop .title-form {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 12px;
	}

	.renovation-shop .foot-btn {
		width: 108px;
		height: 24px;
		font-size: 16px;
		border-radius: 5px;
		text-align: center;
		padding: 6px;
	}

    .renovation-shop .shop-head {
        padding: 16px 20px;
        background-color: #FFFFFF;
        font-size: 18px;
        display: flex;
        border-bottom: 1px #E6E6E6 solid;
        justify-content: space-between;
    }

    .renovation-shop .renovation-card {
        width: 260px;
        height: 220px;
        padding: 20px;
        border: 1px solid #DCDCDC;
    }

    .renovation-shop .renovation-btn {
        padding: 4px 10px;
        color: #FFFFFF;
        margin-right: 20px;
        border-radius: 5px;
    }

    .event-form .top {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

</style>

<style scoped>
    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }

    .renovation-shop .selected {
        background-color: red;
        width: 100%;
        height: 100%;
    }
</style>
