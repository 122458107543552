<template>
    <div  style="width: 100%; min-width:1440px;background: #F2F2F2;height: auto;overflow: hidden;">
        <div style="background: #F2F2F2;">
            <div style="margin-top: 90px">
                <div></div>
                <div style="width: 1500px; height: 64px; margin: 0 auto; display: flex; justify-content: center; line-height: 64px">
                    <div>
                        <span style="font-size: 20px; font-weight: 600; margin-right: 10px; vertical-align: middle">{{myshop.name}}</span>
                        <span style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #2970FF; margin-right: 10px; vertical-align: middle">企业</span>
                        <span style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #7BD7A2; margin-right: 10px; vertical-align: middle">实地认证</span>
                        <span style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #FF9800; margin-right: 10px; vertical-align: middle">10年老店</span>
                        <span v-if="myshop.status === 0" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #008000; margin-right: 10px; vertical-align: middle">保存</span>
                        <span v-if="myshop.status === 1" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #008000; margin-right: 10px; vertical-align: middle">审核中</span>
                        <span v-if="myshop.status === 2" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: red; margin-right: 10px; vertical-align: middle">审核未通过</span>
                        <span v-if="myshop.status === 3" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: #008000; margin-right: 10px; vertical-align: middle">通过</span>
                        <span v-if="myshop.status === 4" style="padding: 2px 6px; font-size: 14px; border-radius: 5px; color: #FFFFFF; background-color: red; margin-right: 10px; vertical-align: middle">查封</span>
                    </div>
                    <div style="color: #999999; margin: 0 10px">
                        <span style="font-weight: 600;"> | </span>
                        <span style="margin-left: 10px">店铺成立时间：</span>
                        <span style="color: #2970FF">2013年</span>
                    </div>
                    <div style="color: #999999; margin: 0 10px">
                        <span style="font-weight: 600;"> | </span>
                        <span style="margin-left: 10px">店铺好评率：</span>
                        <span style="color: #2970FF">100%</span>
                    </div>

                    <div v-if="user.uuid !== myshop.uuid" @click="toMessage(myshop.uuid, myshop.name)" class="hand op" style="margin-left: 180px; font-size: 18px">
                        <i class="el-icon-chat-dot-round" style="color: #2970FF; margin-right: 2px"></i>
                        联系店家
                    </div>
                    <div @click="followShop" v-if="user.uuid !== myshop.uuid && myshop.isCollect === 0" class="hand op" style="margin-left: 60px; font-size: 18px">
                        <i class="el-icon-star-off" style="font-size: 20px; color: #999999"></i>
                        关注店铺
                    </div>
                    <div @click="followShop" v-if="user.uuid !== myshop.uuid && myshop.isCollect === 1" class="hand op" style="margin-left: 60px; font-size: 18px">
                        <i class="el-icon-star-on" style="color: #F12939; font-size: 20px;"></i>
                        取消关注
                    </div>
                </div>
                <div style="width: 100%;height: 120px;background-size: 100% 120px;background-position:center;background-repeat: no-repeat;" :style="{'background-image': `url(${myshop.backgroundImg.img_url})`}"  >
                    <img :src="myshop.logo.img_url" style="width: 120px; height: 120px; margin-left: 248px;">
                </div>
                <div style="display: flex;float: right;margin-right: 50px;">
                    <div v-if="myshop.uuid == user.uuid" style="margin: 10px;">
                        <div style="float: left;">
                            <el-button class="el-icon-s-operation" @click="add">发布服务</el-button>
                        </div>
                        <div style="float: left;margin-left: 20px">
                            <el-button class="el-icon-s-promotion" @click="product">发布产品</el-button>
                        </div>
                        <div style="float: left;margin-left: 20px">
                            <el-button class="el-icon-s-home" @click="decoration">装修店铺</el-button>
                        </div>
                        <div style="float: left;margin-left: 20px">
                            <el-button class="el-icon-coin" @click="addbackstage" >后台管理</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div style=" width:100%;height: 46px;background: #515151;display: flex;justify-content: center; color: #FFFFFF">
                <div v-for="(item,index) in payType" :key="index" class="pay-type-item" >
                    <div @click="addswitch(item.type)"  style="float: left;width: 160px;height: 46px; text-align: center; line-height: 46px;cursor: pointer" :class="{ active: item.type === pay }">
                        <div class="pay-type-name">{{item.name}}</div>
                    </div>
                </div>
            </div>

            <!--首页-->
            <div v-if="pay === 1">
                <Overview :shopData="myshop"></Overview>
            </div>

            <!--装修店铺-->
            <div v-if="pay === 6">
                <RenovationShop :shopId="myshop.id"></RenovationShop>
            </div>

            <!--  买服务  -->
            <div v-if="pay === 2" style="width:100%;min-height:1000px;">
                   <serviceCard :id="myshop.id"></serviceCard>
            </div>
            <!--  买产品  -->
            <div v-if="pay === 3" style="width: 100%;min-height:1000px;">
                <productCard :id="myshop.id"></productCard>
            </div>
            <div v-if="pay === 4">
                <div style="width: 1500px;min-height:1000px;">
                    <caseWell :id="myshop.id"></caseWell>
                </div>
            </div>
            <!--  店铺介绍     -->
            <div v-if="pay === 5" style="margin-left: 200px;width: 1500px">
                <div style="width: 1500px;background: #FFFFFF;height: 400px;">
                    <shopIntroduction :myshop="myshop"></shopIntroduction>
                </div>
                <div style="width: 1500px;background:#F2F2F2;margin-top: 10px;float: left">
                    <sellWell :id="myshop.id" :myshop="myshop"></sellWell>
                </div>
            </div>
        </div>

        <el-dialog :close-on-click-modal="false" class="show-message" :visible.sync="showMessage" width="940px">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import sellWell from "./components/myShop/sellWell";
import caseWell from "./components/myShop/caseWell";
import serviceCard from "./components/myShop/serviceCard";
import productCard from "./components/myShop/productCard";
import RenovationShop from "./renovationShop";
import Overview from "./components/overview";
import shopIntroduction from "./components/myShop/shopIntroduction";
import ToMessage from "./components/order/ToMessage";
export default {
    props: {
        id:{
            type:Number,
        },
    },
    components: {
        ToMessage,
        Overview,
        RenovationShop,
        sellWell,
        caseWell,
        serviceCard,
        productCard,
        shopIntroduction
    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data () {
        return {
            payType:[{name:"首页",type:1},{name:"买服务",type:2},{name:"买产品",type:3},{name:"看案例",type:4},{name:"店铺介绍",type:5}],
            myshop:{
                logo: {
                    image_url: "",
                },
                backgroundImg: {
                    image_url: "",
                },
            },
            pay: 1,
            market:"",
            GoodsList:[],

            showMessage: false,
            uuid: '',
            nickName: '',
        }
    },
    mounted:function(){
        var that = this;
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        that.getMyShop();
    },
    methods:{
        // 私信
        toMessage(uuid, name) {
            this.uuid = uuid;
            this.nickName = name;
            this.showMessage = true
        },

        // 关注、取消关注店铺
        followShop() {
            this.newApi.collectShop({shopId: this.myshop.id}).then((res)=>{
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data)
                    this.getMyShop()
                }
            })
        },

        // 跳转首页
        toOverview() {
            this.getMyShop();
            this.pay = 1;
        },

        // 店铺后台跳转
        addbackstage(){
            window.open("/shop/shopBackstage/")
        },
        addswitch(type){
            this.pay = type;
        },

        decoration(){
            var that = this;
            that.pay = 6;
        },
        add(){
            var that = this;
            that.utils.a("/shop/addGoods/")
        },
        product(){
            var that = this;
            that.utils.a("/shop/product/")
        },
        // 查询我的店铺
        getMyShop(){
	        const that = this;
	        const data = {};
	        if (typeof(that.$route.query.id) !== "undefined") {
				data.id = that.$route.query.id
			}
            that.newApi.getMyShop(data).then((ret)=>{
                that.myshop = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
    }
}
</script>

<style scoped>
.old {
    width: 130px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #DDDDDD;
}
.colod {
    width: auto;
    height: 21px;
    font-size: 18px;
    color: #666666;
}

.pay-type-item {
    float: left;
    width: 160px;
    height: 46px;
}

.pay-type-name {
    height: 24px;
    font-weight: 600;
    font-size: 18px;
}
.active {
    width: auto;
    background: #A8A8A8;
}

.dialog-title {
    height: 30px;
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

/deep/ .show-message .el-dialog__header{
    padding: 0;
}

/deep/ .show-message .el-dialog__body{
    padding: 0;
}
</style>
